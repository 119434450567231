import React from 'react';

const SynergyLogo = (props) => {
    return (
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 721.38 1080">
            <path fill={props.fill} d="M357.52,555.66c114.56,52.87,201.87,94.86,213.99,192.85,.95-7.41,1.45-15.07,1.45-23,0-115.64-91.88-160-215.44-217.03C203.59,437.57,66.92,366.63,54.32,197.09c-.62,7.12-.95,14.18-.95,21.15,0,188.51,142.57,262.96,304.15,337.42Z"/>
            <path fill={props.fill} d="M373.36,6.32c-121.98,0-186.93,85.54-186.93,164.75,0,8.7,.57,16.99,1.69,24.91,10.92-72.16,74.64-142.49,185.24-142.49,137.82,0,215.44,115.64,215.44,115.64l99.8-80.79s-7.65-11.64-22.87-28.66l-76.93,62.28S511.18,6.32,373.36,6.32Z"/>
            <path fill={props.fill} d="M365.44,1026.51c-197.15,0-297.24-130-335-195.07l-18.26,10.96s90.3,231.28,353.26,231.28c194.85,0,343.76-120.39,343.76-300.98,0-8.73-.26-17.19-.76-25.41-11.47,168.43-156.09,279.23-343,279.23Z"/>
        </svg>
    )}

export default SynergyLogo