//React Imports
import React from 'react'

/**
 * Main element for the recital page.
 */
const Recital = () => {
  return (
    <div>
      <p>Hello :DDDD</p>
    </div>
  )
}

export default Recital